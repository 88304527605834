<template>
  <div>
    <section class="max-w-6xl px-6 py-20 mx-auto">
      <div class="w-full lg:w-1/2">
        <h2 class="mb-4 text-2xl font-bold text-eta-primary sm:text-3xl">
          {{ $t("partners") }}
        </h2>
      </div>
      <div
        class="grid grid-cols-1 gap-5 mx-auto bg-white sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div
          class="flex items-center justify-center py-20"
          v-for="partner in partners"
          :key="partner.id"
        >
          <a :href="partner.url" target="_blank">
            <img
              :src="getBaseUrl() + partner.image.url"
              :alt="partner.url"
              class="h-12"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "Partners",
  data() {
    return {
      partners: "",
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const partners = await axios.get(`${baseUrl}/partners`);
      this.partners = partners.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped></style>

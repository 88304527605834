<template>
  <section class="max-w-4xl px-6 py-20 mx-auto">
    <div class="text-sm legals">
      <VueShowdown class="content" :markdown="`${privacy.content}`" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "PrivacyPolicy",
  data: () => ({
    privacy: {},
  }),
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/privacy-policy`);
      this.privacy = response.data;
      console.log("we are here");
      console.log(response.data);
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>

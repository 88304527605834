<template>
  <div>
    <section class="max-w-6xl px-6 py-16 mx-auto">
      <h2 class="text-2xl font-bold text-eta-primary">{{ $t("mission") }}</h2>
      <p>
        <VueShowdown class="content" :markdown="`${mission.content}`" />
      </p>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Mission",
  data() {
    return {
      mission: [],
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiMission",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.mission = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>

<template>
  <div>
    <section class="max-w-6xl px-6 py-20 mx-auto">
      <!-- <h1 class="mb-4 text-4xl font-bold text-eta-primary">
           Events
         </h1> -->
      <div>
        <div class="overflow-hidden text-gray-600">
          <div class="container px-5 mx-auto">
            <div class="inline-flex">
              <select
                name="filter"
                v-model="filter"
                id="filter"
                class="w-full px-8 py-1 text-gray-700 transition-colors duration-200 ease-in-out border border-gray-400 rounded outline-none hover:bg-gray-200 focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
              >
                <option value="">Filter</option>
                <option value="name">By Name</option>
                <option value="date">By Date</option>
              </select>
            </div>
            <div
              v-if="filter === 'name'"
              class="p-6 mt-4 bg-white rounded shadow-sm"
            >
              <label for="name" class="block mb-2 text-sm"
                >Filter By Name</label
              >
              <input
                v-model="name"
                type="text"
                class="w-full px-3 py-2 text-sm placeholder-gray-400 border rounded md:w-96 hover:border-gray-400"
                placeholder="Name of event"
              />
              <button
                @click="clearFilterByName"
                class="px-3 py-2 mt-4 text-sm bg-gray-300 border border-gray-400 rounded md:mt-0 md:ml-6 hover:bg-gray-400 hover:text-gray-100"
              >
                Clear filter
              </button>
            </div>
            <div
              v-if="filter === 'date'"
              class="flex flex-wrap p-6 mt-4 bg-white rounded shadow-sm md:space-x-10"
            >
              <div class="w-full md:w-auto">
                <label for="strtDate" class="block mb-2 text-sm"
                  >Start Date</label
                >
                <input
                  v-model="startDate"
                  type="date"
                  class="w-full px-3 py-2 text-sm border rounded md:w-48 hover:border-gray-400"
                  placeholder="start date"
                />
              </div>
              <div
                class="w-full mx-0 mt-4 md:w-auto md:mt-0 md:mx-6 md:ml-none"
              >
                <label for="endDate" class="block mb-2 text-sm">End Date</label>
                <input
                  v-model="endDate"
                  type="date"
                  class="w-full px-3 py-2 text-sm border rounded appearance-none md:w-48 hover:border-gray-400"
                  placeholder="end date"
                />
              </div>
              <div
                class="flex flex-col-reverse mt-4 md:mt-0 md:ml-6 md:items-end"
              >
                <button
                  @click="clearFilterByDate"
                  class="px-3 py-2 text-sm bg-gray-300 border border-gray-400 rounded hover:bg-gray-400 hover:text-gray-100"
                >
                  Clear filter
                </button>
              </div>
            </div>
          </div>
          <div class="container px-5 py-20 mx-auto">
            <div class="-my-8 divide-y-2 divide-gray-100">
              <div
                class="flex flex-wrap py-8 md:flex-nowrap"
                v-for="event in filterdEvents"
                :key="event.id"
              >
                <div class="md:flex-grow">
                  <h2
                    class="mb-3 text-2xl font-medium text-gray-900 title-font"
                  >
                    {{ event.title }}
                    <span class="block text-sm text-gray-400">
                      {{ event.date }}</span
                    >
                  </h2>
                  <p class="leading-relaxed">
                    <VueShowdown :markdown="`${event.details}`" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Events",
  data() {
    return {
      events: "",
      filter: "",
      name: "",
      startDate: "",
      endDate: "",
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiEvents",
    }),
    getBaseUrl() {
      return baseUrl;
    },
    filterEventsByDate: function () {
      let filterdEvents = this.events;
      if (
        this.startDate !== "" &&
        this.endDate !== "" &&
        this.startDate &&
        this.endDate
      ) {
        let start = new Date(`${this.startDate}`).getTime();
        let end = new Date(`${this.endDate}`).getTime();
        filterdEvents = filterdEvents.filter((event) => {
          return (
            new Date(`${event.date}`).getTime() >= start &&
            new Date(`${event.date}`).getTime() <= end
          );
        });
      }
      return filterdEvents;
    },
    filterEventsByName: function () {
      let filterdEvents = this.events;
      if (this.name !== "" && this.name) {
        filterdEvents = filterdEvents.filter((event) => {
          return event.title.toUpperCase().includes(this.name.toUpperCase());
        });
      }
      return filterdEvents;
    },

    filterdEvents() {
      return this.filter === "name"
        ? this.filterEventsByName
        : this.filterEventsByDate;
    },
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    clearFilterByName() {
      this.name = "";
    },
    clearFilterByDate() {
      this.startDate = "";
      this.endDate = "";
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.events = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped></style>

<template>
   <div>
      <div class="flex items-center justify-center h-96 hero">
      </div>
      <section class="text-gray-700">
         <what-is-telemedicine></what-is-telemedicine>
         <why-telemedicine></why-telemedicine>
         <AboutUs></AboutUs>
         <Timeline></Timeline>
         <Partners></Partners>
         <Subscribe></Subscribe>
      </section>
   </div>
</template>

<script>
import WhatIsTelemedicine from "../components/home/WhatIsTelemedicine.vue";
import WhyTelemedicine from "../components/home/WhyTelemedicine.vue";
import AboutUs from "../components/home/AboutUs.vue";
import Timeline from "../components/home/Timeline.vue";
import Partners from "../components/home/Partners.vue";
import Subscribe from "../components/home/Subscribe.vue";

export default {
   name: "Home",
   components: {
      Partners,
      AboutUs,
      Timeline,
      Subscribe,
      WhyTelemedicine,
      WhatIsTelemedicine,
   },
};
</script>
<style scoped>
.hero {
   background-image: linear-gradient(
         to right bottom,
         rgba(93, 168, 161, 0.8),
         rgba(0, 10, 34, 0.8)
      ),
      url("../assets/Telemedicine.png");
   background-position: center;
}
</style>

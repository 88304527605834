<template>
  <div>
    <section class="max-w-4xl px-6 py-20 mx-auto">
      <div class="text-sm legals">
        <VueShowdown class="content" :markdown="`${terms.content}`" />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "TermsOfUSe",
  data() {
    return {
      terms: {},
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/terms-of-use`);
      this.terms = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
/* header {
   background-image: url("../assets/header.png");
   min-height: 170px;
}
.hero {
   min-height: 170px;
} */
</style>

<template>
  <div>
    <nav class="px-10 py-4 mx-auto bg-white">
      <div class="lg:flex lg:flex-wrap lg:items-center">
        <div class="flex items-center justify-between">
          <div class="flex flex-col items-center justify-center">
            <img class="h-16" src="@/assets/ETN.svg" alt="" />
          </div>
          <button
            @click="toggle"
            class="px-3 py-1 border border-gray-400 border-solid rounded opacity-70 hover:opacity-75 lg:hidden"
            id="navbar-toggle"
          >
            <svg
              fill="none"
              stroke="currentColor"
              class="w-6 h-6"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        <!--   -->
        <div
          :class="open ? 'flex' : 'hidden'"
          class="flex-col items-center justify-center mt-3 font-medium text-eta-primary lg:flex lg:flex-row lg:ml-auto lg:mt-0"
          id="navbar-collapse"
        >
          <router-link
            v-for="nav in navs"
            :key="nav.name"
            @click.native="scrollToTop"
            :to="nav.route"
            class="py-2 transition-colors duration-300 md:mx-4 hover:opacity-80"
          >
            {{ $t(`nav.${nav.name}`) }}
          </router-link>
        </div>
        <LocaleSwitch class="mt-3 lg:mt-0"></LocaleSwitch>
      </div>
    </nav>
  </div>
</template>

<script>
import LocaleSwitch from "../LocaleSwitch.vue";
export default {
  name: "Header",
  components: { LocaleSwitch },
  data() {
    return {
      navs: [
        {
          name: "home",
          route: "/",
        },
        {
          name: "about-us",
          route: "/about-us",
        },
        {
          name: "events",
          route: "/events",
        },
        {
          name: "services",
          route: "/services",
        },
        {
          name: "resources",
          route: "/resources",
        },
        {
          name: "membership",
          route: "/membership",
        },
        {
          name: "contact-us",
          route: "/contact-us",
        },
      ],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped></style>

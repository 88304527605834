<template>
  <section class="text-gray-600 bg-white">
    <div class="max-w-2xl py-20 mx-auto">
      <div class="flex flex-col w-full mb-4 text-center">
        <h1 class="text-3xl font-semibold text-eta-primary">
          {{ $t("subscribe-to-our-newsletter") }}
        </h1>
      </div>
      <!-- <div class="flex flex-col items-end w-full px-8 mx-auto space-y-4 lg:w-2/3 sm:flex-row sm:space-x-4 sm:space-y-0 sm:px-0">
      <div class="relative flex-grow w-full">
        <input type="email" id="email" name="email" placeholder="Enter your email" class="w-full px-3 py-1 text-base leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-gray-100 bg-opacity-50 border border-gray-300 rounded outline-none focus:border-ghc-primary focus:bg-transparent">
      </div>
      <button class="px-8 py-2 text-lg text-white border-0 rounded bg-ghc-primary focus:outline-none hover:opacity-90"> Subscribe </button>
    </div> -->
      <div class="p-10">
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div class="flex flex-wrap">
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="fName" class="block mb-2 font-medium">
                    {{ $t("first-name") }}
                  </label>
                  <ValidationProvider
                    name="First name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="fName"
                      v-model="fName"
                      name="firstName"
                      :placeholder="$t('first-name')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="lName" class="block mb-2 font-medium">
                    {{ $t("last-name") }}
                  </label>
                  <ValidationProvider
                    name="Last name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="lName"
                      v-model="lName"
                      name="lastName"
                      :placeholder="$t('last-name')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="title" class="block mb-2 font-medium">
                    {{ $t("title") }}
                  </label>
                  <ValidationProvider
                    name="Title"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      name="title"
                      v-model="title"
                      id="title"
                      class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                    >
                      <option value="">Title</option>
                      <!-- <option v-for="title in titles" :key="title.id" :value="title.range">
                                 {{ title.title }}
                              </option> -->
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof">Prof</option>
                      <option value="other">other</option>
                    </select>
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="title" class="block mb-2 font-medium">
                    {{ $t("age") }}
                  </label>
                  <ValidationProvider
                    name="Age"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      name="age"
                      v-model="age"
                      id="age"
                      class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                    >
                      <option value="">Age</option>
                      <!-- <option v-for="age in ages" :key="age.id" :value="age.range">
                                 {{ title.title }}
                              </option> -->
                      <option value="Under 12 years">Under 12 years</option>
                      <option value="12-17 years old">12-17 years old</option>
                      <option value="18-24 years old">18-24 years old</option>
                      <option value="25-34 years old">25-34 years old</option>
                      <option value="35-44 years old">35-44 years old</option>
                      <option value="45-54 years old">45-54 years old</option>
                      <option value="55-64 years old">55-64 years old</option>
                      <option value="65-74 years old">65-74 years old</option>
                      <option value="75 years or older">
                        75 years or older
                      </option>
                    </select>
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label for="profession" class="block mb-2 font-medium">
                    {{ $t("profession") }}
                  </label>
                  <ValidationProvider
                    name="Profession"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="profession"
                      v-model="profession"
                      name="profession"
                      :placeholder="$t('profession')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="flex w-full p-2 my-8 item-center bg-gray-50">
                <div class="p-4">
                  <span class="block mb-2 font-medium">{{ $t("gender") }}</span>
                  <div class="mt-2">
                    <ValidationProvider
                      name="Type Of Appointment"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label class="inline-flex items-center">
                        <input
                          v-model="gender"
                          type="radio"
                          class="w-4 h-4 form-radio"
                          name="gender"
                          value="male"
                        />
                        <span class="ml-2 text-sm">{{ $t("male") }}</span>
                      </label>
                      <label class="inline-flex items-center ml-6">
                        <input
                          v-model="gender"
                          type="radio"
                          class="w-4 h-4 form-radio"
                          name="gender"
                          value="female"
                        />
                        <span class="ml-2 text-sm">{{ $t("female") }}</span>
                      </label>
                      <div class="text-sm text-red-600">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label for="lName" class="block mb-2 font-medium">
                    {{ $t("company") }}
                  </label>
                  <ValidationProvider
                    name="Company"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="company"
                      v-model="company"
                      name="company"
                      placeholder="Company name"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label for="email" class="block mb-2 font-medium">
                    {{ $t("email") }}
                  </label>
                  <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <input
                      type="email"
                      id="email"
                      v-model="email"
                      name="email"
                      :placeholder="$t('email')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="flex justify-end w-full p-2 mt-4">
                <button
                  type="submit"
                  class="w-full px-4 py-2 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-eta-primary focus:outline-none hover:opacity-90"
                >
                  {{ $t("subscribe") }}
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  data() {
    return {
      fName: "",
      lName: "",
      title: "",
      age: "",
      profession: "",
      gender: "",
      company: "",
      email: "",
      error: "",
    };
  },
  methods: {
    showToast() {
      this.$toast.success("You have subscribed successfully");
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(`${baseUrl}/subscriptions`, {
              firstName: this.fName,
              lastName: this.lName,
              title: this.title,
              age: this.age,
              gender: this.gender,
              profession: this.profession,
              company: this.company,
              email: this.email,
            })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
              console.log(error);
            });
          this.$nextTick(() => {
            // this.$refs.form.reset();
            // this.fName = "";
            // this.lName = "";
            // this.phone = "";
            // this.email = "";
            this.showToast();
          });
        } catch (error) {
          this.error = error;
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <section class="max-w-6xl px-6 py-16 pt-20 mx-auto">
      <div class="mb-4">
        <h2 class="text-2xl font-bold text-eta-primary">{{ $t("values") }}</h2>
      </div>
      <div class="flex mb-3 space-x-2" v-for="list in values" :key="list.id">
        <svg
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#1c6376"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
          />
        </svg>
        <p>
          {{ list.title }}
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "Values",
  data() {
    return {
      values: [],
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiValues",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.values = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
<style scoped></style>

<template>
  <section class="max-w-6xl px-6 py-20 mx-auto">
    <div v-for="about in abouts" :key="about.id">
      <h1 class="mb-4 text-4xl font-bold text-eta-primary">
        {{ about.title }}
      </h1>
      <p>
        <VueShowdown class="content" :markdown="`${about.content}`" />
      </p>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "AboutUs",
  data() {
    return {
      abouts: [],
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiAbouts",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.abouts = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

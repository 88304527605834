<template>
  <div class="bg-white">
    <section class="max-w-6xl px-6 mx-auto mt-20 mb-10">
      <div
        class="mb-8 uppercase md:flex md:items-center md:justify-center md:p-10"
      >
        <h1 class="mb-4 font-bold text-center md:mb-0 md:w-3/5">
          Brief history of <br />
          <span class="text-eta-primary">telemedicine</span>
        </h1>
        <div class="text-sm uppercase md:w-2/5">
          <p>
            Telemedicine is the delivery of health care services, where distance
            is a critical factor using information and communication
            technologies.
          </p>
        </div>
      </div>
      <div class="relative h-full overflow-hidden md:p-10 wrap">
        <div
          class="absolute h-full border border-gray-700 border-2-2 border-opacity-20"
          style="left: 50%"
        ></div>
        <!-- right timeline -->
        <div
          class="flex items-center justify-between w-full mb-32 right-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">1</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-secondary"
          >
            <h3 class="mb-3 text-xl font-bold text-black">1840s</h3>
            <p
              class="text-sm leading-snug tracking-wide text-black text-opacity-100"
            >
              The telegraph was used to order medical supplies and transmit
              casualty reports.
            </p>
          </div>
        </div>

        <!-- left timeline -->
        <div
          class="flex flex-row-reverse items-center justify-between w-full mb-20 left-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">2</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-primary"
          >
            <h3 class="mb-3 text-xl font-bold text-white">1879</h3>
            <p
              class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
            >
              Telephone used to reduce unnecessary hospital visits.
            </p>
          </div>
        </div>

        <!-- right timeline -->
        <div
          class="flex items-center justify-between w-full mb-16 right-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">3</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-secondary"
          >
            <h3 class="mb-3 text-xl font-bold text-black">1906</h3>
            <p
              class="text-sm leading-snug tracking-wide text-black text-opacity-100"
            >
              The Inventor of the electrocardiogram published a paper on the
              telecardiogram.
            </p>
          </div>
        </div>

        <!-- left timeline -->
        <div
          class="flex flex-row-reverse items-center justify-between w-full mb-22 left-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">4</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-primary"
          >
            <h3 class="mb-3 text-xl font-bold text-white">1920s</h3>
            <p
              class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
            >
              The Radio was used to give medical advice to clinics on ships.
            </p>
          </div>
        </div>
        <!-- right timeline -->
        <div
          class="flex items-center justify-between w-full mb-8 right-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">1</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-secondary"
          >
            <h3 class="mb-3 text-xl font-bold text-black">1950 - 1960s</h3>
            <p
              class="text-sm leading-snug tracking-wide text-black text-opacity-100"
            >
              Earliest and most famous uses of hospital-based telemedicine.
            </p>
          </div>
        </div>

        <!-- left timeline -->
        <div
          class="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">2</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-primary"
          >
            <h3 class="mb-3 text-xl font-bold text-white">1960s</h3>
            <p
              class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
            >
              Studies showed high rates of agreement between diagnoses made in
              person and diagnoses made via teledermatology.
            </p>
          </div>
        </div>

        <!-- right timeline -->
        <div
          class="flex items-center justify-between w-full mb-8 right-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">3</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-secondary"
          >
            <h3 class="mb-3 text-xl font-bold text-black">1993</h3>
            <p
              class="text-sm leading-snug tracking-wide text-black text-opacity-100"
            >
              The american Telemedicine association was launched.
            </p>
          </div>
        </div>

        <!-- left timeline -->
        <div
          class="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">4</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-primary"
          >
            <h3 class="mb-3 text-xl font-bold text-white">1996</h3>
            <p
              class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
            >
              The institute of medicine released its report telemedicine: A
              guide to assessing telecommunications for healthcare.
            </p>
          </div>
        </div>
        <!-- right timeline -->
        <div
          class="flex items-center justify-between w-full mb-8 right-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">3</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-secondary"
          >
            <h3 class="mb-3 text-xl font-bold text-black">2000</h3>
            <p
              class="text-sm leading-snug tracking-wide text-black text-opacity-100"
            >
              Sentara healthcare launched tele-ICU.
            </p>
          </div>
        </div>

        <!-- left timeline -->
        <div
          class="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">4</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-primary"
          >
            <h3 class="mb-3 text-xl font-bold text-white">2010</h3>
            <p
              class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
            >
              More than 5,000 intensive care unit beds in nearly 250 hospitals
              were being covered via tele-ICU.
            </p>
          </div>
        </div>
        <!-- right timeline -->
        <div
          class="flex items-center justify-between w-full mb-8 right-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">3</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-secondary"
          >
            <h3 class="mb-3 text-xl font-bold text-black">2020/2021</h3>
            <p
              class="text-sm leading-snug tracking-wide text-black text-opacity-100"
            >
              Ministry of health, Ethiopia launched Telehealth implementation
              guideline, practical tips.
            </p>
          </div>
        </div>
        <div
          class="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline"
        >
          <div class="order-1 w-5/12"></div>
          <div
            class="z-20 flex items-center order-1 w-8 h-8 bg-gray-800 rounded-full shadow-xl"
          >
            <!-- <h1 class="mx-auto text-lg font-semibold text-white">4</h1> -->
          </div>
          <div
            class="order-1 w-5/12 px-2 py-4 rounded-lg shadow-xl md:px-6 bg-eta-primary"
          >
            <h3 class="mb-3 text-xl font-bold text-white">2022</h3>
            <p
              class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
            >
              Ethiopian Telemedicine Network (ETN) was established.
            </p>
          </div>
        </div>
      </div>
      <div class="py-10">
        <h2
          class="text-lg font-bold tracking-wide text-center uppercase text-eta-primary"
        >
          Ethiopian Telemedicine Network
        </h2>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Timeline",
};
</script>

<style></style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Events from "../views/Events.vue";
import Services from "../views/Services.vue";
import Resources from "../views/Resources.vue";
import Membership from "../views/Membership.vue";
import Contact from "../views/Contact";
import TermsOfUse from "../views/TermsOfUse";
import PrivacyPolicy from "../views/PrivacyPolicy";
Vue.use(VueRouter);
// Vue.prototype.$scrollToTop = () =>{
//   window.scrollTo(0, 0)
// }
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/membership",
    name: "Membership",
    component: Membership,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
